import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import filesize from 'filesize';
import { useTranslation } from "react-i18next";
import Cookies from 'js-cookie';
import toastr from 'toastr';
import { observer } from 'mobx-react';
import fileSize from 'filesize';

import Api from '../../api/Api';
import UploadProcessModal from './UploadProcessModal';

import uploadStore from '../../mobx/UploadStore';
import webSocketStore from '../../mobx/WebsocketStore';
import { MAX_UPLOAD_SIZE_PER_FILE } from '../../constants/fileSize';

const UploadModal = observer((props) => {
  const { t } = useTranslation();
  const [ processShow, setProcessShow ] = useState(false);
  const [ checkedItems, setCheckedItems ] = useState([]);
  const [ checkedAll, setCheckedAll ] = useState(false);
  const [ uploader, setUploader ] = useState('');
  const [ memo, setMemo ] = useState('');
  const [ total, setTotal ] = useState(0);
  const [ receivedInitKey, setReceivedInitKey ] = useState('');

  const { files, setFiles } = uploadStore;

  const reducer = (acc, cur) => acc + cur;

  const [, updateState] = React.useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  const isIE = Api.detectIE();

  useEffect(() => {
    if (files.length !== 0) {
      const temp = [];
      files.map(item => temp.push(item.size));

      setTotal(temp.reduce(reducer));
    }
  }, [ files, checkedItems, checkedAll, props.link ]);

  const onDrop = useCallback(acceptedFiles => {
    const temp = files;
    const tempSize = [];
    const tempNames = temp.map(item => item.name);

    temp.map(item => tempSize.push(item.size));

    acceptedFiles.forEach(item => {
      if (tempNames.includes(item.name)) {
        return;
      }

      tempSize.push(item.size);
      if (props.link.quota_size !== 0 && props.link.remaining_size < tempSize.reduce(reducer)) {
        toastr.remove();
        toastr.error(t('You exceed the maximum capacity.'));
        return;
      }

      temp.push(item);
    })

    setFiles([...temp]);
  }, [files, props.link.quota_size, props.link.remaining_size, setFiles, t]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleCheckItem = (e, checkedItem) => {
    const temp = checkedItems;
    if (e.target.checked) {
      temp.push(checkedItem);
    } else {
      checkedItems.forEach((item, i) => {
        if (item === checkedItem) {
          checkedItems.splice(i, 1);
        }
      });
      setCheckedAll(false);
    }
    
    setCheckedItems([...temp]);
  }

  const handleCheckItemsAll = (e) => {
    if (e.target.checked) {
      setCheckedAll(true);
      const temp = [];
      files.forEach((item, i) => {
        temp.push(`${item.lastModified}-${item.name}`);
      });

      setCheckedItems([...temp]);
    } else {
      setCheckedAll(false);
      setCheckedItems([]);
    }
  }

  const deleteItems = () => {
    if (checkedItems.length === 0) {
      toastr.remove();
      toastr.error(t('There is no file selected.'));
      return;
    }

    const temp = files;
    const tempCheckedItems = checkedItems;
    let tempSize = total;
    tempCheckedItems.forEach(checked => {
      temp.forEach((item, i) => {
        if (`${item.lastModified}-${item.name}` === checked) {
          temp.splice(i, 1);
          tempSize = tempSize - item.size;
        }
      });
    })

    setTotal(tempSize);
    setFiles([...temp]);
    setCheckedItems([]);
    setCheckedAll(false);
  }

  const initUpload = () => {
    const remain = (100 - ((props.link.remaining_size - total) / props.link.quota_size * 100)).toFixed(1);
    if (props.link.quota_size !== 0 && remain > 100) {
      toastr.remove();
      toastr.error(t('You exceed the maximum capacity.'));
      return;
    }

    if (files.length === 0) {
      toastr.remove();
      toastr.error(t('There is no file selected.'));
      return;
    }

    if (Cookies.get(`${props.linkId}_email`) === undefined && uploader === '') {
      console.log(Cookies.get(`${props.linkId}_email`), uploader);
      toastr.remove();
      toastr.error(t('Enter name'));
      return;
    } 

    const url = '/openapi/link/initUpload';
    const params = {
      token: Cookies.get(props.linkId),
      lang: t('lang'),
    }

    Api.postData(
      url,
      params,
      (res) => {
        if (res.success) {
          console.log('success', res);
          if (!isIE) {
            webSocketStore.connectSocket();
          }
          const key = res.result.initkey;
          setProcessShow(!processShow);
          uploadStore.setCount(files.length);

          setTimeout(() => {
            handleUploadNew(key);
          }, 1000);
        } else {
          console.log('failure', res);
          toastr.error(res.all);
        }
      },
      (res) => {
        console.log('[server]failure', res);
        toastr.error(res.all);
      }
    )
  }

  const handleUploadNew = (key) => {
    const { current, count } = uploadStore;

    const fileKey = Math.random().toString(36).substr(2, 16);

    const formData = new FormData();
    formData.append('token', Cookies.get(props.linkId));
    formData.append('file_key', fileKey);
    formData.append('name', files[current].name);
    formData.append('upload_size', files[current].size);
    formData.append('node', props.currentNode);
    formData.append('uploader', Cookies.get(`${props.linkId}_email`) || uploader);
    // formData.append('initkey', current === 0 ? key : receivedInitKey);
    formData.append('initkey', key);
    formData.append('file', files[current]);
    formData.append('lang', t('lang'));
    formData.append('memo', memo);

    const currentFiles = files;
    currentFiles[current].fileKey = fileKey;
    setFiles(currentFiles);

    let url = `/v1/link/${props.linkId}/files/sync`;
    if (!isIE) {
      url = `/v1/link/${props.linkId}/files?client_key=${uploadStore.client_key}`;
    }

		const uploadNextFile = () => {
			if (current + 1 !== count) {
				uploadStore.setCurrent(current + 1);
				handleUploadNew(key);
			} else {
				uploadComplete(key);
			}
		}

		if (currentFiles[current].size > MAX_UPLOAD_SIZE_PER_FILE) {
			currentFiles[current].error_message = t('The file size exceeds the allowable limit per file. Please contact the system administrator.')
			currentFiles[current].status = 'error';
			uploadStore.setCurrentStatus('error');

			uploadNextFile();
		}

    console.log(url, files);
    Api.fileUploadNew(
      url,
      formData,
      files[current].name,
      (res) => {
        if (res.status === 200) {
          console.log('complete', res);

          if (!res.data.success) {
            if (isIE) {
              uploadStore.setStatus('error', res.data.all);
            } else {
              currentFiles.map((item, i) => {
                if (item.fileKey === fileKey) {
                  currentFiles[i].error_message = res.data.all;
                  currentFiles[i].status = 'error';
                  uploadStore.setCurrentStatus('error');
                }
              })
            }
          } else {
            setReceivedInitKey(res.data.initkey);

            if (isIE) {
              uploadStore.setStatus(res.data.data.upload_status);
            } else if (!isIE && !webSocketStore.ws) {
              // ws 연결이 안되었을때
              webSocketStore.getUploadStatus(files);
            }
            
          }

          uploadNextFile();
        } else {
          console.log('error', res);
          if (isIE) {
            uploadStore.setStatus('error', res.data.all);
          }

          uploadNextFile();
        }
      },
      (res) => {
        console.log('[server]failure', res);
        setProcessShow(false);
        toastr.error(res.all);
        if (res.result_code === '91') {
          // 업로드 가능용량 리로드
          props.handleGetSizeInfo();
          setTimeout(() => {
            forceUpdate();
          }, 500)
        }
      }, () => {
				currentFiles.map((item, i) => {
					if (item.fileKey === fileKey) {
						currentFiles[i].error_message = t('You have no authority.');
						currentFiles[i].status = 'error';
						uploadStore.setCurrentStatus('error');
					}
				})
			}
    )
  }

  const handleUpload = (key) => {
    const { current, count } = uploadStore;

    const url = '/openapi/link/upload';
    const formData = new FormData();
    formData.append('Filedata', files[current]);
    formData.append('initkey', key);
    formData.append('uploader', Cookies.get(`${props.linkId}_email`) || uploader);
    formData.append('node', props.currentNode);
    formData.append('token', Cookies.get(props.linkId));
    formData.append('size', files[current].size);
    formData.append('memo', memo);

    console.log(total);
    Api.fileUpload(
      url,
      formData,
      files[current].name,
      (res) => {
        if (res.success) {
          console.log('success', res);
          if (current + 1 !== count) {
            uploadStore.setCurrent(current + 1);
            handleUpload(key);
          } else {
            uploadComplete(key);
          }
        } else {
          console.log('failue', res);
          setProcessShow(false);
          toastr.error(res.all);
        }
      },
      (res) => {
        console.log('[server]failure', res);
        setProcessShow(false);
        toastr.error(res.all);
        if (res.result_code === '91') {
          // 업로드 가능용량 리로드
          props.handleGetSizeInfo();
          setTimeout(() => {
            forceUpdate();
          }, 500)
        }
      }
    )
  }

  const uploadComplete = (initKey) => {
    const url = '/openapi/link/uploadComplete';
    const params = {
      token: Cookies.get(props.linkId),
      initkey: initKey,
      memo: memo,
    }

    console.log('uploadComplete()', url, params);
    Api.postData(
      url,
      params,
      (res) => {
        if (res.success) {
          console.log('success', res);
          if (!isIE && !webSocketStore.ws) {
            // ws 연결이 안되었을때
            console.log('complete getUploadStatus')
            webSocketStore.getUploadStatus(files, true);
          }

          uploadStore.setComplete();

          return;
        } else {
          console.log('failure', res);
        }
      },
      (res) => {
        console.log('[server]failure', res);
        // setProcessShow(false);
        toastr.error(res.all);
        if (isIE) {
          uploadStore.setComplete();
        }
      }
    )
  }

  const renderTitle = () => {
    const styles = {
      wrap: {
        padding: '3px 6px',
        backgroundColor: '#F6F6F6',
      },
      chk: {
        width: '7%',
        display: 'inline-block',
        verticalAlign: 'middle',
      },
      name: {
        width: '63%',
        display: 'inline-block',
        verticalAlign: 'middle',
      },
      size: {
        width: '30%',
        display: 'inline-block',
        verticalAlign: 'middle',
      }
    }
    return (
      <div style={styles.wrap}>
        <div style={styles.chk}>
          <input
            type='checkbox'
            onChange={(e) => {
              handleCheckItemsAll(e);
            }}
            checked={checkedAll}
          />
        </div>
        <div style={styles.name}>
          <span>{t('File Name')}</span>
        </div>
        <div style={styles.size}>
          <span>{t('Size')}</span>
        </div>
      </div>
    );
  }

  const renderFileListItem = (item, i) => {
    const styles = {
      wrap: {
        padding: '3px 6px',
        backgroundColor: i % 2 === 0 ? '#EAEAEA' : '#F6F6F6',
        height: '28px',
      },
      chk: {
        width: '7%',
        display: 'inline-block',
        lineHeight: 'inherit',
        float: 'left',
      },
      name: {
        width: '63%',
        display: 'inline-block',
        lineHeight: 'inherit',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        float: 'left',
        paddingRight: '5px',
      },
      size: {
        width: '30%',
        display: 'inline-block',
        lineHeight: 'inherit',
        float: 'left',
      }
    }
    return (
      <div style={styles.wrap} key={`${item.lastModified}-${item.name}-${i}`}>
        <div style={styles.chk}>
          <input
            type='checkbox'
            onChange={(e) => {
              handleCheckItem(e, `${item.lastModified}-${item.name}`)
            }}
            checked={checkedItems.includes(`${item.lastModified}-${item.name}`)}
          />
        </div>
        <div style={styles.name}>
          <span>{item.name}</span>
        </div>
        <div style={styles.size}>
          <span>{filesize(item.size)}</span>
        </div>
      </div>
    );
  }

  const styles = {
    listWrap: {
      height: '140px',
      backgroundColor: isDragActive ? '#FAF4C0' : '#F6F6F6',
      border: isDragActive && '5px solid #FFE400',
      marginBottom: '20px',
      overflow: 'auto',
    },
  }

  return (
    <div className='UploadModal'>
      <div className='um__dialog'>
        <div className='um__header'>
          <span style={{ margin: 0, fontWeight: 'bold' }}>{t('Upload')}</span>
          <div
            className='um__header-btn'
            onClick={() => {
              props.setUpload(false);
            }}
          >
            <img
              src="/static/bundles/img/ic_close.svg"
              alt=""
              style={{
                width: '10px',
                height: '10px',
              }}
            />
          </div>
        </div>
        <div className='um__dialog-content'>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '8px' }}>
            <div style={{ display: 'inline-block'}}>
              <label htmlFor="upload-input" className='um__dialog-content-btn'>
                <i className="fa fa-plus" />
                {t('Add')}
              </label>
              <label className='um__dialog-content-btn' onClick={() => deleteItems()}>
                <i className="fa fa-minus" />
                {t('Delete')}
              </label>
            </div>
            <div className='content__info-sizewrap'>
              <i className="fa fa-hdd-o" style={{ fontSize: '16px' }}></i>
                <span className='content__info-cr'>{t('Capacity Restriction')}</span>
                <span
                  style={{
                    color: '#8C8C8C',
                  }}
                >
                  {
                    props.link.quota_size === 0 ?
                      `${fileSize(props.link.upload_size + total)} / ${t('Capacity Unlimited')}` :
                      `${fileSize(props.link.upload_size + total)}
                        (${(100 - ((props.link.remaining_size - total) / props.link.quota_size * 100)).toFixed(1)}%)
                        / ${fileSize(props.link.quota_size)}`
                  }
                </span>
            </div>
          </div>
          {renderTitle()}
          <div
            {
              ...getRootProps({
                onClick: event => event.stopPropagation()
              })
            }
          >
            <input
              {...getInputProps()}
              id="upload-input"
            />
            <div style={styles.listWrap} className='um__dialog-list-wrap'>
              {
                files && files.length !== 0 ?
                  files.map((item, i) => renderFileListItem(item, i)) :
                  <p
                    className="um__drop-text"
                    style={{
                      textAlign: 'center',
                      marginTop: '60px',
                      fontSize: '13px',
                      color: '#666'
                    }}
                  >
                    {t('Please drag and drop files or click + button to upload.')}
                  </p>
              }
            </div>
          </div>
          {
            // 수신자 인증 기능 사용할 경우 안보임
            // cookie에 있는 _email을 확인해 없을 시 보임
            // 최대 50자이내
            Cookies.get(`${props.linkId}_email`) === undefined && (
              <div style={{ marginBottom: '10px'}}>
                <p>
                  <i className="fa fa-user"/>
                  {t('Name')}
                </p>
                <input
                  type='text'
                  className='um__dialog-content-input'
                  placeholder={t('Enter name')}
                  maxLength='50'
                  value={uploader}
                  onChange={(e) => {
                    const regExp = /[ 　『』‘～！＠＃＄％＾＆＊（）＿＋「」｛｝；：’”、＜。＞・？￥＿＋＝－\{\}\[\]\/?.,;:|\)*~`!^\-_+┼<>@\#$%&\'\"\\\(\=]/gi;
                    // const regExp = /[^\x01-\x7Ea-zA-zㄱ-ㅎ가-힣0-9一-龥]/gi;
                    
                    if (regExp.test(e.target.value)) {
                      const value = e.target.value;
                      setUploader(value.replace(regExp, ''));
                      const agent = window.navigator.userAgent.toLowerCase();
                      const isIphone = /iphone|ipod|ipad/.test(agent);
                      if (window.innerWidth < 767 && isIphone) {
                        e.target.blur();
                      }
                      
                      toastr.remove();
                      toastr.error(t('Special Characters are not allowed in the name.'));
                    } else {
                      setUploader(e.target.value);
                    }
                  }}
                />
              </div>
            )
          }
          <div style={{ marginBottom: '10px'}}>
            <p>
              <i className="fa fa-pencil" />
              {t('Message')}
            </p>
            <textarea onChange={(e) => { setMemo(e.target.value) }}/>
          </div>
          <div style={{ height: '50px' }}>
            <button
              className='um__dialog-send'
              onClick={(e) => {
                initUpload();
              }}
            >
              <span>{t('Send')}</span>
            </button>
          </div>
        </div>
      </div>
      {
        processShow && (
          <UploadProcessModal
            completeMessage={
              props.link.type === 'file' ?
                'Completed sending files.' :
                'File upload has been completed.'
            }
            hide={(error) => {
              props.setUpload(false);
              uploadStore.setInit();
              webSocketStore.disconnectSocket();
              if (!error) {
                // if (props.link.type === 'file') {
                //   toastr.success(t('Completed sending files.'));
                // } else {
                //   toastr.success(t('File upload has been completed.'));
                // }
              }
              // props.getLink();
              props.handleGetSizeInfo();
              props.getList();
            }}
          />
        )
      }
    </div>
  )
});

export default UploadModal;
